@font-face {
  font-family: 'HKGrotesk';
  src: local('HKGrotesk'), url(./fonts/HKGrotesk-Regular.otf) format('opentype');
}

@font-face {
  font-family: "BeVietnam-BoldItalic";
  src: local("BeVietnam-BoldItalic"),
    url("./fonts/BeVietnam-BoldItalic.ttf") format("truetype");
}

@font-face {
  font-family: "BeVietnam-ExtraBold";
  src: local("BeVietnam-ExtraBold"),
    url("./fonts/BeVietnam-ExtraBold.ttf") format("truetype");
}

@font-face {
  font-family: "BeVietnam-ExtraBoldItalic";
  src: local("BeVietnam-ExtraBoldItalic"),
    url("./fonts/BeVietnam-ExtraBoldItalic.ttf") format("truetype");
}

@font-face {
  font-family: "BeVietnam-Italic";
  src: local("BeVietnam-Italic"),
    url("./fonts/BeVietnam-Italic.ttf") format("truetype");
}

@font-face {
  font-family: "BeVietnam-Light";
  src: local("BeVietnam-Light"),
    url("./fonts/BeVietnam-Light.ttf") format("truetype");
}

@font-face {
  font-family: "BeVietnam-LightItalic";
  src: local("BeVietnam-LightItalic"),
    url("./fonts/BeVietnam-LightItalic.ttf") format("truetype");
}

@font-face {
  font-family: "BeVietnam-Medium";
  src: local("BeVietnam-Medium"),
    url("./fonts/BeVietnam-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "BeVietnam-MediumItalic";
  src: local("BeVietnam-MediumItalic"),
    url("./fonts/BeVietnam-MediumItalic.ttf") format("truetype");
}

@font-face {
  font-family: "BeVietnam-Regular";
  src: local("BeVietnam-Regular"),
    url("./fonts/BeVietnam-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "BeVietnam-SemiBoldItalic";
  src: local("BeVietnam-SemiBoldItalic"),
    url("./fonts/BeVietnam-SemiBoldItalic.ttf") format("truetype");
}

@font-face {
  font-family: "BeVietnam-Thin";
  src: local("BeVietnam-Thin"),
    url("./fonts/BeVietnam-Thin.ttf") format("truetype");
}

@font-face {
  font-family: "BeVietnam-ThinItalic";
  src: local("BeVietnam-ThinItalic"),
    url("./fonts/BeVietnam-ThinItalic.ttf") format("truetype");
}

@font-face {
  font-family: "picadilly-regular";
  src: local("picadilly-regular"),
    url("./fonts/picadilly-regular.otf") format("truetype");
}

@font-face {
  font-family: "picadilly-bold";
  src: local("picadilly-bold"),
    url("./fonts/picadilly-bold.otf") format("truetype");
}

@font-face {
  font-family: "picadilly-italic";
  src: local("picadilly-italic"),
    url("./fonts/picadilly-italic.otf") format("truetype");
}

body {
  margin: 0;
  padding: 0;  
}

/* After patch v4.12 adjustments were needed */
div[role=presentation] {
  z-index: 1500 !important;
}

#FaceMatchDialog h2, #DocumentValidationDialog h2, 
#SelectGroupConfigurationDialog h2, #CheckboxEnableValueDialog h2, 
#CheckboxEnableValuePJDialog h2 {
  font-size: 20px;
}

#confirmationDialogText {
  font-family: 'Be Vietnam', sans-serif !important;
  font-size: 16px !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px #f0f8fa inset !important;
}

.MuiFilledInput-root, .MuiFilledInput-{
  background-color: transparent !important;
  transition: none !important;
}

.MuiFilledInput-underline {
  padding-top: 6px;
  padding-bottom: 6px;
  font-family: 'Be Vietnam', sans-serif !important;
}

#bigid-text-helper-text {
  font-size: 12px;
  font-family: 'Be Vietnam', sans-serif !important;
}

#bigid-text.MuiInputBase-input {
  padding: 2px 5px !important;
  font-family: 'Be Vietnam', sans-serif !important;
  background-color: none;
}

.MuiButton-root{
  background-color: #0069ff !important;
}

#save-button{
  background-color: transparent !important;
}

.MuiFilledInput-underline::before, .MuiFilledInput-underline::after {
  content: none !important;
}

#ListItemText span {
  font-family: 'Be Vietnam', sans-serif !important;
  font-size: 16px !important;
}

div.MuiFilledInput-underline input {
  text-align: center;
}

.MuiFormControlLabel-labelPlacementStart {
  align-self: baseline;
  margin-left: 0px !important;
}

span.MuiFormControlLabel-label {
  font-family: 'Be Vietnam', sans-serif !important;
  font-weight: bold;
}

.MuiDialogContent-root {
  font-family: 'Be Vietnam', sans-serif !important;
}

div.MuiDialogTitle-root * {
  font-family: 'Picadilly', sans-serif !important;
}

#hourTimePick-label, #minuteTimePick-label, #secondsTimePick-label {
  font-size: 16px;
  font-family: 'Be Vietnam', sans-serif !important;
}

.displayNone {
  display: none;
}

.displayBlock {
  display: block;
}
/* ********************************************* */

.MuiInput-underline:after {
  border-bottom: 2px solid #ff003c !important;
}

.MuiInputBase-input {
  text-align: left !important;
  font-size: 16px !important;
  padding: 5px 10px !important;
  background-color: transparent !important;
}


.MuiPopover-root {
  z-index: 9999 !important;
}

.MuiTypography-body1 {
  font-size: 14px !important;
  font-family: 'Be Vietnam', sans-serif !important;
}

.collapse-wrapper {
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  /* background-color: #BFBDC0; */
  background-color: #9C999C;
  box-shadow: rgba(50, 50, 50, 0.5) 13px 13px 13px -6px inset;
  color: white;
}

.content .MuiFormLabel-root {
  color: black !important;
}

.btn-microsoft {
  background-color: rgb(255, 255, 255);
    display: inline-flex;
    align-items: center;
    color: rgba(0, 0, 0, 0.54);
    box-shadow: rgba(0, 0, 0, 0.24) 0px 2px 2px 0px, rgba(0, 0, 0, 0.24) 0px 0px 1px 0px;
    padding: 0px;
    border-radius: 2px;
    border: 1px solid transparent;
    font-size: 14px;
    font-weight: 500;
    font-family: Roboto, sans-serif;
    padding: 10px 10px 10px 0px;
    font-weight: 500;
    margin-top: 15px;
    min-width: 100%;
    border-radius: 5px;
}

.svg-microsoft {
  margin-left: 9px;
}

.txt-microsoft {
  margin-left: 16px;
}

#sso-wrapper button {
  min-width: 100% !important;
  border-radius: 5px !important;
}

#load-modal div {
  background-color: "#ffffff00";
  box-shadow: "none"
}

.MuiOutlinedInput-notchedOutline {
  border-color: white !important;
}


header .MuiInputBase-input, .MuiFormLabel-root {
  color: white !important;
}

.MuiAutocomplete-popper {
  z-index: 100 !important;
}

.login-popup-error {
  display: flex;
  justify-content: center;
  top: 96%;
  background-color: #ff003c;
  position: absolute;
  color: white;
  min-width: 100%;
  min-height: 40px;
  font-size: 20px;
  padding-top: 5px;
}

/** Login **/
@media(max-width: 900px) {
  .login-popup-error {
    font-size: 12px;
    padding-top: 5px;
  }
}

/** Header **/
.sandwich-menu {
  display: none;
}

.outside-click {
  touch-action: none;
  user-select: none;
  -webkit-user-drag: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  display: none;
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 50;
  left: 0;
  background: rgba(0, 0, 0, 0.7);
}

.side-outside-click {
  touch-action: none;
  user-select: none;
  -webkit-user-drag: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  display: none;
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 50;
  left: 0;
  background: rgba(0, 0, 0, 0.7);
}

.side-nav {
  height: 100%;
  width: 305px;
  background: #0069ff;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 50;
  -webkit-box-shadow: 3px 0 6px rgba(0, 0, 0, 0.29);
  -moz-box-shadow: 3px 0 6px rgba(0, 0, 0, 0.29);
  box-shadow: 3px 0 6px rgba(0, 0, 0, 0.29);
}

.side-nav.menuActive {
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
  right: 0;
}

.side-nav:not(.menuActive) {
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
  right: -310px;
}

.side-nav ul {
  list-style-type: none;
  padding: 0px;
}

.side-nav li {
  border-bottom: 1px solid white;
  padding: 5px;
}

.side-nav a {
  color: #fff;
  cursor: pointer;
  font-weight: 700;
  font-size: 22px;
  line-height: 40px;
  text-decoration: none;
}

@media(max-width: 900px) {
  #no-href {
    margin: 0 !important;
    color: white;
    text-decoration: none;
    cursor: pointer;
    font-weight: 700;
    font-size: 22px;
    line-height: 40px;
    list-style-type: none;
    padding-left: 25px;
  }
  .hello-content-mobile {
    display: flex;
    align-items: center;
    grid-gap: 10px !important;
    padding: 30px !important;
    font-size: 24px;
    color: white;
  }

  .hello-content-mobile img{
    display: block;
    margin-top: 5px;
  }
  header div:first-child {
    margin: inherit !important;
  }

  .block {
    display: block !important;
  }
  header .container {
    min-width: auto !important;
  }
  .menu-content {
    display: none;
  }
  .hello-content {
    display: none !important;
  }
  .sandwich-menu {
    display: block !important;
  }
  .sandwich-menu {
    padding-right: 30px;
    margin-top: 30px;
    padding-bottom: 10px;
    padding-left: 30px;
  }
 
}

/** Main Content **/
.content-wrapper ul #list-item-0 {
  margin-top: 15px;
}

.content-wrapper .MuiInput-root svg {
  height: 36px;
  width: 36px;
}

.select-wrapper .MuiInput-underline:before {
  border-bottom: 2px solid white;
}

/** Countdown Timer **/
#countdown-timer {
  transform: scale(0.5);
}

#countdown-timer div {
  margin: auto;
}

@media(max-width: 900px) {
  .timer {
    margin-left: 40px !important;
  }
}


@media(max-width: 900px) {
  .content {
    margin-left: 0px !important;
    min-width: 100% !important;
    max-width: 100% !important;
  }

  .content-wrapper {
    display: block !important;
  }

  .content-wrapper div:first-child {
    width: 100%;
  }
  
  .question-card {
    width: 135px !important;
    margin: auto;
  }
}

/** Main SideMenu**/
.sub-list-text span, .list-text span {
  font-weight: bold;
  font-family: 'Be Vietnam', sans-serif !important;
}

.sub-list:hover {
  background-color: #BFBDC0 !important;
}

.list-item:hover, .sub-list:hover, .list-item:focus, .sub-list:focus {
  text-decoration: underline !important;
}

.select-wrapper .MuiSelect-selectMenu {
  color: #0069ff !important;
}

.select-wrapper .MuiInputBase-root.MuiInput-root.MuiInput-underline{
  left: 75%;
  width: 170px;
  margin-top: 10px;
}

.mobile-side-menu {
  display: none;
}

.mobile-side-menu {
  background-color: rgb(242, 242, 242);
  padding-bottom: 10px;
}

.side-menu-nav {
  height: 100%;
  display: none;
  width: 305px;
  background: #f2f2f2;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 50;
  -webkit-box-shadow: 3px 0 6px rgba(0, 0, 0, 0.29);
  -moz-box-shadow: 3px 0 6px rgba(0, 0, 0, 0.29);
  box-shadow: 3px 0 6px rgba(0, 0, 0, 0.29);
}

.side-menu-nav.menuActive {
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
  left: 0;
}

.side-menu-nav:not(.menuActive) {
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
  left: -310px;
}

.save-button {
  border-radius: 20px !important;
  background-color: transparent !important;
  border: none;
  font-weight: 950;
}


.list-item-save-button div {
  background-color: rgb(242 242 242) !important;
  box-shadow: none !important;
  color: #213262;
  font-weight: 950;
}

.list-item-save-button .MuiButton-contained {
  background-color: unset;
}


@media(max-width: 900px) {
  .side-menu-wrapper {
    display: none !important;
  }

  .mobile-side-menu {
    display: flex;
    flex-direction: row-reverse;
  }

  .mobile-side-menu .select-wrapper .MuiInputBase-root.MuiInput-root.MuiInput-underline {
    left: 0%;
    min-width: 80%;
    margin-left: 30px;
  }

  .mobile-side-menu .select-wrapper {
    margin: auto;
  }
  .mobile-side-menu .MuiInput-underline:before {
    border-bottom: 2px solid black;
  }
  .side-menu-nav .MuiList-root.MuiList-padding {
    padding: 0px !important;
  }
  .side-menu-body {
    display: none !important;
  }

  .side-menu-nav {
    display: block;
  }
  
}

/** Footer **/

#social-icons li {
  margin-right: 20px;
  max-height: 30px;
}

footer ul {
  font-size: 11.5px;
  line-height: 1em;
}

footer b {
  font-size: 13px;
}

#select-language{
  display: flex !important;
  align-items: center !important;
}

#select-language .MuiInput-underline:before {
  border-bottom: 1px solid white !important;
}

#select-language .MuiSelect-icon {
  color: white !important;
}

@media(max-width: 900px) {
  .footer-wrapper {
    padding: 0px !important;
    display: flex !important;
    flex-direction: column-reverse;
  }

  .footer-wrapper ul {
    margin-left: 0px !important;
  }

  .footer-wrapper div {
    margin-left: 0px !important;
    margin-bottom: 5px;
  }
}

/** Validations **/

#validations .MuiSelect-nativeInput {
  opacity: 1 !important;
}

/** Poc Generator **/

@media(max-width: 900px) {
  .poc-radio-button {
    width: 20px !important;
  }
  
  .poc-radio-button div {
    width: 20px !important;
  }
}

/** Background Check **/
@media(max-width: 900px) {
  .bgc-wrapper {
    flex-direction: column;
  }
  .bgc-top-content, .bgc-bottom-content {
    width: 100% !important;
  }
  .bgc-bottom-content div {
    max-width: 100% !important;
  }
  .bgc-wrapper .common-textfield-wrapper div {
    max-width: 100px !important;
  }
  .bgc-toggle-content-wrapper {
    flex-direction: column;
  }
}


/** Ab Test **/
@media(max-width: 900px) {
  .ab-test-table {
    width: 100% !important;
  }
  tr {
    margin-top: 10px;
  }
}

/** Login **/
@media(max-width: 900px) {
  .login-wrapper {
    flex-direction: column !important;
    top: initial !important;
    left: initial !important;
    position: initial !important;
    transform: none !important;
  }
  
  .login-wrapper img {
    width: 230px !important;
      padding-top: 30px !important;
  }
}

/* Report */
.react-datetime-picker__calendar {
  z-index: 500;
}

@media (max-width: 760px) {

	/* Force table to not be like tables anymore */
	table, thead, tbody, th, td, tr { 
		display: block; 
	}
	
	/* Hide table headers (but not display: none;, for accessibility) */
	thead tr { 
		position: absolute;
		top: -9999px;
		left: -9999px;
	}
	
	tr { border: 1px solid #ccc; }
	
	td { 
		/* Behave  like a "row" */
		border: none;
		border-bottom: 1px solid #eee; 
		position: relative;
		padding-left: 50%; 
	}
	
	td:before { 
		/* Now like a table header */
		position: absolute;
		/* Top/left values mimic padding */
		top: 6px;
		left: 6px;
		width: 45%; 
		padding-right: 10px; 
		white-space: nowrap;
  }
}


